
import { Link } from "react-router-dom";
import { Seo } from '../layouts/Seo';

// https://www.pluralsight.com/resources/blog/guides/load-and-render-json-data-into-react-components
import { pressData } from '../data/press'

const Press = () => {

    // get all the news titles and use them as the page description
    let press_descr = "";
    pressData.map((data, key) => (
        press_descr = press_descr + data.title + "; "
    ));

    return (
    <>

    <Seo
        title="Saigon A.I. - Press and Media Relations"
        description={press_descr}
        type="webapp"
      />

    <div class="breadcrumb">
        <span class="caret">&gt;</span>
        <span class="link"><Link to="/press">Press and Media</Link></span>
    </div>
    <h1>Press and Media</h1>
    <div class="d-none">{press_descr}</div>


    <p> 
            For media inquiries, please contact: / Mọi thắc mắc về truyền thông, vui lòng liên hệ:<br/>            
            <Link to="mailto:press@saigonai.com">press@saigonai.com</Link><br/>
            tel: +84 28 667 02 220
    </p>

    <div class="container-fluid">
        <div class="row">

            {pressData.map((data, key) => {
                return (
                    <>
                    {/* begin press feature */}
                    <div key={key} class="col-md-5 press_feature">
                        <div class="press_media_type badge bg-light text-dark">{data.type}</div>
                        <div class="press_img_wrapper"><img class="press_img" alt="{data.title}" src={data.img}/></div>
                        <div class="press_text"> 
                            <div class="press_date">{data.date}</div>   
                            <div class="press_title">
                                <p>{data.title}</p>
                                {data.links.map((link) => {
                                    return (
                                        <>
                                        <a class="btn btn-xs btn-warning" href={link.uri}>{link.name}</a><br/>
                                        </>                            
                                    )}
                                )}
                            </div>
                        </div> 
                    </div>
                    {/* end press feature */}
                    </>
                );
            })}

        </div>
    </div>

    </>
    )
  };
  
  export default Press;