import { Link } from "react-router-dom";
import { Seo } from '../layouts/Seo';

const Jobs = () => {
    return (
    <>

    <Seo
        title="Saigon A.I. - Job Opportunities - Available Positions and How to Apply"
        description="Data Analysts, Data Engineers, Software Engineers, Internships"
        type="webapp"
      />
    <div class="breadcrumb">
        <span class="caret">&gt;</span>
        <span class="current">Work Opportunities</span>
    </div>
    <h1>Working at Saigon A.I.</h1>

  
    <div class="py-3 px-3">
    <p>This page contains information about current openings.  Please read the information carefully, <br/>
    including all the information required to <Link to="/jobs/process" class="btn btn-xs btn-warning">submit your application</Link>.</p>
    <p></p>
    <strong><Link to="/jobs/key_things">Key things to know before you apply.</Link></strong>
<p></p>
    Click on a <strong>job</strong> listing below for the job description:
    <p></p>    
    <ul class="list-group w-50">
        <li class="list-group-item"><i class="fas fa-pie-chart fa-fw me-4"></i><Link to="/jobs/data_analyst" className="btn">Data Analyst</Link></li>
        {/*
        <li class="list-group-item"><i class="fas fa-pie-chart fa-fw me-4"></i><Link to="/jobs/ds_coordinator" className="btn">Data Science Project Coordinator</Link></li>
        */}
        <li class="list-group-item"><i class="fas fa-pie-chart fa-fw me-4"></i><Link to="/jobs/project_coordinator" className="btn">Project Coordinator</Link></li>
        {/*
        <li class="list-group-item"><i class="fas fa-pie-chart fa-fw me-4"></i><Link to="/jobs/python_reviewer" className="btn">Mid-level Python Engineer - Data Science Team</Link></li> 
        */}
        <li class="list-group-item"><i class="fas fa-pie-chart fa-fw me-4"></i><Link to="/jobs/biz_dev" className="btn">Business Development Assistant</Link></li> 
    </ul>
    <p></p>
    Click on an <strong>internship</strong> listing below for the job description:
    <p></p>    
    <ul class="list-group w-50">
        <li class="list-group-item"><i class="fas fa-pie-chart fa-fw me-4"></i><Link to="/jobs/ds_intern" className="btn">Data Analyst Intern</Link></li>
        {/*
        <li class="list-group-item"><i class="fas fa-code fa-fw me-4"></i><Link to="/jobs/process" className="btn">Application Process</Link></li>
        <li class="list-group-item"><i class="fas fa-bomb fa-fw me-4"></i>Dapibus ac facilisis in</li>
        <li class="list-group-item"><i class="fas fa-code fa-fw me-4"></i>Morbi leo risus</li>
        <li class="list-group-item"><i class="far fa-gem fa-fw me-4"></i>Porta ac consectetur ac</li>
        <li class="list-group-item"><i class="fas fa-cogs fa-fw me-4"></i>Vestibulum at eros</li>
        */}
        <li class="list-group-item"><i class="fas fa-pie-chart fa-fw me-4"></i><Link to="/jobs/marketing_intern" className="btn">Marketing Intern</Link></li>
        <li class="list-group-item"><i class="fas fa-pie-chart fa-fw me-4"></i><Link to="/jobs/dev_intern" className="btn">Developer Intern</Link></li> 
    </ul>
    <p></p>

    <p>To apply for a position, read the requirements carefully and then read about <Link to="/jobs/process" class="btn btn-xs btn-warning">how to apply</Link>.</p>

    </div>

    </>
    )
  };
  
  export default Jobs;