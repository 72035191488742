import { Link } from "react-router-dom";
import ReactECharts from 'echarts-for-react';
import { useNavigate } from "react-router-dom";
import { Seo } from '../layouts/Seo';

// https://www.pluralsight.com/resources/blog/guides/load-and-render-json-data-into-react-components
import { capabilityData } from '../data/capabilities'

const SankeyChart = () => {

  const data = capabilityData;  
  
  const options = {
    title: {
        // text: 'Sankey Diagram'
      },
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove',
        show: false
      },
      series: [
        {
          type: 'sankey',
          layout: 'none',
          data: data.nodes,
          links: data.links,
          emphasis: {
            focus: 'adjacency'
          },
          label: {
            show: true,
            color: "white",
            fontSize: 12,
          }
        }
      ]
    };
  
    const navigate = useNavigate();

    const onChartClick = (params) => {

        //console.log('Chart clicked', params);
        if (params.data.href) {
            //console.log('Linking to ',  params.data.href)
            navigate("/capabilities/" + params.data.href);
        }
    };
    
    const onEvents = {
        click: onChartClick,
    };

    return <ReactECharts option={options} onEvents={onEvents} />;
  };

const Capabilities = () => {
    return (
    <>

    <Seo
        title="Saigon A.I. - Capabilities - The Technologies We Work With and Solutions We Offer"
        description="Language Models, Retrieval Augmented Generation, Data and Process Auditing; Data Analysis;"
        type="webapp"
      />

        <div class="breadcrumb">
            <span class="caret">&gt;</span>
            <span class="current">Capabilities</span>
        </div>

        <h1>Our Capabilities and Experience</h1>

        <p>
          Our <Link to="/solutions">business solutions</Link> utilize these technical capabilities to provide world class, scalable solutions.  Our <Link to="/about/culture">culture</Link> of <Link to="/about/learning">learning</Link> means that we are always adding new capabilities.
        </p>

        <div>
            <span >Explore Saigon A.I.'s Technical Solutions and Capabilities</span>
            {/* <Link to="" className="white"  data-bs-toggle="tooltip" title="Capabilities are related to tech skills. Hover over each topic to see how capabilities relate to each other.  Click on a label for more information.">
                <i class="fa fa-question-circle" aria-hidden="true"></i>
            </Link> */}
        </div>

        <SankeyChart />

        <div>
            <Link to="/capabilities/dataaudit" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/data_audit">Data And Process Auditing</Link>
            <Link to="/capabilities/dataengineering" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/data_audit">Data Engineering</Link>
            <Link to="/capabilities/language_models" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/language_models">Language Models</Link>
            <Link to="/capabilities/dashboard" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/dashboard">Dashboarding</Link>
            <Link to="/capabilities/nlp" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/nlp">Natural Language Processing</Link>
            <Link to="/capabilities/ai_ml_modeling" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/ml_model">AI and Machine Learning Modeling</Link>
            <Link to="/capabilities/deep_learning" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/deep_learning">Deep Learning</Link>
            <Link to="/capabilities/data_analysis" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/data_analysis">Data Analysis</Link>
            <Link to="/capabilities/behavioral" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/behavioral">Behavioral Data</Link>
            <Link to="/capabilities/generative_ai" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/genai">Generative A.I.</Link>
            <Link to="/capabilities/explainable_ai" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/explainable_ai">Explainable A.I.</Link>
        </div>

    </>
    )
  };
  
  export default Capabilities;