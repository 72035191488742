import { Link } from "react-router-dom";


const Automation = () => {
    return (
    <>

        <div class="breadcrumb">
            <span class="caret">&gt;</span>
            <span class="link"><Link to="/solutions">Solutions</Link></span>
            <span class="caret">&gt;</span>
            <span class="current">Automation</span>
        </div>
        <h1>Transform Your Business with AI Automation</h1>


<div class="row featurette">
    <div class="col-md-7">
      <p class="lead">In today's fast-paced world, businesses know that AI can streamline operations, boost productivity, and offer a competitive edge. But for many, the challenge lies in knowing where to begin. </p>

      <h2 class="featurette-heading">You might<span class="text-muted"> be asking:</span></h2>
          <ul>
            <li>How do I integrate AI into my business processes?</li>
            <li>What areas of my business would benefit most from AI?</li>
            <li>How can automation help me scale efficiently?</li>
          </ul>

        <p><strong>This is where Saigon AI comes in.</strong></p>
        <p>We understand that AI and automation can feel complex. That's why we specialize in guiding businesses—whether you're in logistics, finance, or e-commerce—through every step of the AI adoption process.</p>
        <p><strong>Why Choose Saigon AI?</strong></p>
        <strong>Custom AI Solutions: </strong>No one-size-fits-all approach here. We develop AI strategies and automation tailored to your unique business needs.
        <strong>Proven Expertise: </strong>With experience working with international brands, we focus on real-world solutions like fraud prediction, sales and inventory optimization, and process automation.
        <strong>End-to-End Support: </strong>From consultation to deployment, our team is with you throughout your AI journey.
        <p></p>
        <p><strong>Ready to Start?</strong></p>
        <p>AI has the potential to revolutionize your business—but you don't have to figure it out on your own. Get in touch with our team today, and we’ll help you unlock the power of AI automation, step by step.</p>
        <p>Let's talk about how we can help you automate and grow your business.</p>

      <div>
      Contact Us Today!&nbsp;
        <Link to="/contact" class="btn btn-sm btn-primary" href="#contact" onclick="load_page(this)" data-content="content/contact">Contact us</Link>
      </div>
    </div>
    <div class="col-md-5">
      <img src="/img/ai_design.jpeg" class="max-px-300 roundy" alt="AI And Machine Learning"/>
    </div>
  </div>

  <div class="py-3">&nbsp;</div>


    </>
    )
  };
  
  export default Automation;