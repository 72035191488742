import { Link } from "react-router-dom";

const JobsDataAnalystIntern = () => {
    return (
    <>

    <div class="breadcrumb">
        <span class="caret">&gt;</span>
        <span class="link"><Link to="/jobs">Work Opportunities</Link></span>
        <span class="caret">&gt;</span>
        <span class="current">Data Analyst Intern</span>
    </div>

    <h1>Data Analyst Intern</h1>

    <div class="wpb_wrapper">

        
    <strong><font color="#2272FF">Job Description:</font></strong>
    <p></p>
    We are seeking a highly motivated Data Analyst Intern to join our dynamic team. This internship provides a unique opportunity to gain hands-on experience in data analysis, working on real projects that contribute to the growth and success of Saigon AI. As a Data Analyst Intern, you will assist in collecting, analyzing, and interpreting data to support our AI-driven solutions and business strategies.
    <p></p>
    <strong><font color="#2272FF">Key Responsibilities:</font></strong>
    <p></p>
    <ul>
        <li>Assist in the collection, cleaning, and preparation of data for analysis.</li>
        <li>Perform exploratory data analysis (EDA) to uncover trends, patterns, and insights.</li>
        <li>Support the development of dashboards and reports to visualize data and communicate findings.</li>
        <li>Conduct research to identify new data sources and methods to enhance analysis.</li>
        <li>Document data processes, methodologies, and findings.</li>
        <li>Enhance self-learning skills.</li>
    </ul>
    <p></p>
    <strong><font color="#2272FF">Qualifications:</font></strong>
    <p></p>
    <ul>
        <li>Currently pursuing or recently completed a degree in Data Science, Statistics, Mathematics, Computer Science, Economics, International Business or a related field.</li>
        <li>Strong analytical and problem solving skills.</li>
        <li>Attention to detail and a strong desire to learn and grow.</li>
        <li>Ability to work independently and as part of a collaborative team.</li>
</ul>
    <p></p>
    <strong><font color="#2272FF">A Plus:</font></strong>
    <p></p>
    <ul>
        <li>Experience with data cleaning, preprocessing, and transformation techniques.</li>
        <li>Familiarity with machine learning concepts and data modeling.</li>
        <li>Understanding of statistical analysis and hypothesis testing.</li>
        <li>Proficiency in data analysis tools and programming languages such as Python, R, or SQL.</li>
        <li>Basic knowledge of data visualization tools like Tableau, Power BI, or similar.</li>
    </ul>
    <p></p>
    <strong><font color="#2272FF">What We Offer:</font></strong>
    <p></p>
    <ul>
        <li>Hands-on experience with real-world data projects.</li>
        <li>Mentorship from experienced data scientists and analysts.</li>
        <li>A supportive and innovative work environment.</li>
</ul>
 
<div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
<span class="vc_sep_holder vc_sep_holder_l">
    <span class="vc_sep_line"></span>
</span>
<span class="vc_sep_holder vc_sep_holder_r">
    <span class="vc_sep_line"></span>
</span>
</div>
</div>
<Link to="/jobs/process" class="btn btn-warning">How To Apply</Link>


    </>
    )
  };
  
  export default JobsDataAnalystIntern;