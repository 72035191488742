import { Link } from "react-router-dom";


const DataEngineering = () => {
    return (
    <>

        <div class="breadcrumb">
            <span class="caret">&gt;</span>
            <span class="link"><Link to="/capabilities">Capabilities</Link></span>
            <span class="caret">&gt;</span>
            <span class="current">Data Engineering</span>
        </div>
        <h1>Data Engineering</h1>

    <div>
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/story">AWS</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/learn">Azure</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/process">Oracle</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/culture">Python</Link> 
    </div>

    <h2 class="featurette-heading">Turning Data <span class="text-muted"> Into Value.</span></h2>

    <div class="row featurette">
        <div>
          <p class="lead">
            Data engineering services play a crucial role in today’s data-driven businesses. Services include the design, development, and maintenance of systems and processes that aggregate, store, and transform large volumes of data. 
            
            <ul class="pad-v5 ">
                <li>Data Integration: This process involves collecting and integrating data from various sources into a centralized system. Data engineers use modern technologies to ensure data consistency, accuracy, and real-time access across an organization.</li>
                <li>ETL & Data Transformation: ETL (Extract, Transform, Load) processes help organizations collect, process, and analyze large volumes of data efficiently. Data experts seamlessly integrate data from multiple sources, ensuring unparalleled accuracy and consistency. Innovative data transformation techniques eliminate redundancies and unlock the true potential of data assets.</li>
                <li>Data Pipeline Development: Data engineers design and implement robust, scalable data pipelines tailored to specific needs. These pipelines automate data processes, reduce manual intervention, and drive optimal data-driven outcomes1.</li>
                <li>Lifecycle and Monitoring: An often overlooked part of a good data ecosystem, knowing how your data changes over time and monitoring for data drift can keep you aware of when models need rebuilt and when data quality issues arise.</li>
            </ul>
            </p>
          
            <div>Some of our clients use this service from these industries:</div>
            <ul>
              <li>Computer Hardware</li>
              <li>Heathcare</li>
              <li>Finance</li>
            </ul>
  
            <div>
              To discuss your project and how we can help &nbsp;
              <Link to="/contact" class="btn btn-sm btn-primary" href="#contact" onclick="load_page(this)" data-content="content/contact">Contact us</Link>
            </div>

        </div>
      </div>
  
  
      <div class="py-3">&nbsp;</div>

    </>
    )
  };
  
  export default DataEngineering;