import React, {useEffect} from 'react';
import { Link } from "react-router-dom";
import HubspotForm from 'react-hubspot-form'
import { Seo } from '../layouts/Seo';

// use Openlayer map
// ref: https://mxd.codes/articles/how-to-create-a-web-map-with-open-layers-and-react
import 'ol/ol.css';
import { Map, View} from 'ol';
import { OSM } from 'ol/source';
import TileLayer from 'ol/layer/Tile';
import * as olProj from 'ol/proj'
import Overlay from "ol/Overlay";

function MapComponent() {
  
  useEffect(() => {
    const center = olProj.transform([106.676076, 10.780813], 'EPSG:4326', 'EPSG:3857');
    const osmLayer = new TileLayer({
          preload: Infinity,
          source: new OSM(),
      })

      const map = new Map({
          target: "map",
          layers: [osmLayer],
          view: new View({
              center: center,
              zoom: 3,
            }),
        });

       const marker = new Overlay({
          position: center,
          positioning: "center-center",
          element: document.getElementById("marker"),
          stopEvent: false
       });
       //console.log(this.marker);
    
       // Adding to the Map Object
       map.addOverlay(marker);

      return () => map.setTarget(null)
  }, []);

  return (
    <div style={{height:'300px',width:'100%'}} id="map" className="map-container roundy" />
  );
}

const Contact = () => {
    return (
    <>

<Seo
        title="Saigon A.I. - Contact Information -- We Want to Hear From You"
        description="How to reach Saigon AI, address, phone, social media, facebook linked-in "
        type="webapp"
      />

      <div class="container-fluid d-flex flex-column flex-shrink-0 p-3 ">
      <div class="row">

        <div class="col-md-6">
          <img src="/img/contact_banner.jpg" alt="Contact Saigon A.I." class="max-w-100 roundy" />
          <h3 class="rscontact-title">Send Us a Message</h3>
          <HubspotForm
                        portalId='8948467'
                        formId='b112cc9d-784a-46da-9d70-7aaf3b3297a7'
                        onSubmit={() => console.log('Submit!')}
                        onReady={(form) => console.log('Form ready!')}
                        loading={<div>Loading...</div>}
                    />
          </div>

          <div class="col-md-6">
              
            <MapComponent/>
            <div 
          id="marker" 
          title="Marker"
          style={{
            width: "20px",
            height: "20px",
            border: "1px solid #088",
            borderRadius: "10px",
            backgroundColor: "#0FF",
            opacity: "0.5"
          }}
          />

              <div class="py-3">  

              <div class="wpb_wrapper">

                <h3 class="rscontact-title">Headquarters</h3>
                <p><i class="fa-fw fa-solid fa-map-pin"></i> 292 Cach Mang Thang 8, District 3, HCMC, Vietnam</p>
                <ul class="contact_phone">
                    <li>
                        Phone 1: 
                        <a href="tel:+84399406879">028 667 02 220 (VN)</a>
                    </li>
                    <li>
                        Phone 2: 
                        <a href="tel:+16109553408"> +1 610-955-3408 (US)</a>
                    </li>
                    <li>
                      Email: 
                      <Link to="mailto:info@saigonai.com"> info@saigonai.com</Link>
                    </li>
              </ul>

              Find us on social media:
                <ul class="contact_social_icon">
                  <li>
                      <a class="social_link" href="https://twitter.com/SaigonAI" target="_blank" rel="noreferrer">
                        <i class="fa-brands fa-fw fa-square-x-twitter"></i>Twitter
                      </a>
                  </li>
                  <li>
                      <a href="https://www.facebook.com/SaigonAi.4U" target="_blank" rel="noreferrer">
                        <i class="fa-brands fa-fw fa-facebook"></i>Facebook
                      </a>
                  </li>
                  <li>
                      <a href="https://www.linkedin.com/company/saigonai" target="_blank" rel="noreferrer">
                        <i class="fa-brands fa-fw fa-linkedin"></i>linkedin
                      </a>
                  </li>
                  <li>
                      <a href="https://www.instagram.com/saigon_ai/" target="_blank" rel="noreferrer">
                        <i class="fa-brands fa-fw fa-instagram" aria-hidden="true"></i>Instagram
                      </a>
                  </li>
              </ul>


            </div>

              </div>
          </div>

      </div>
      </div>


    </>
    )
  };
  
  export default Contact;