import { Link } from "react-router-dom";

const JobsMarketingIntern = () => {
    return (
    <>

    <div class="breadcrumb">
        <span class="caret">&gt;</span>
        <span class="link"><Link to="/jobs">Work Opportunities</Link></span>
        <span class="caret">&gt;</span>
        <span class="current">Marketing Intern</span>
    </div>

    <h1>Marketing Intern</h1>

    <div class="wpb_wrapper">

    <strong><font color="#2272FF">Job Description:</font></strong> 
<p></p>
We are seeking a dynamic and motivated Marketing Intern to join our team. This is an excellent opportunity to gain hands-on experience in the tech industry, work on exciting projects, and contribute to the growth of an innovative AI company. As a Marketing Intern at Saigon AI, you will work closely with our marketing and communications team to assist in various aspects of marketing strategy, content creation, and brand development.
<p></p>
<strong><font color="#2272FF">Key Responsibilities:</font></strong>
<p></p>
    <ul>
        <li>Come ready to learn.</li>
        <li>Assist in developing and executing digital marketing campaigns across various platforms, including social media, email, and web.</li>
        <li>Support content creation efforts by writing blog posts, articles, newsletters, and social media content that resonate with our target audience.</li>
        <li>Conduct market research to identify trends, competitor activities, and opportunities for growth.</li>
        <li>Help manage and grow our social media presence by creating engaging content and interacting with followers.</li>
        <li>Assist in organizing and promoting events, webinars, and other marketing initiatives.</li>
        <li>Collaborate with the design team to create visually appealing marketing materials.</li>
        <li>Analyze marketing data and provide insights to optimize campaign performance.</li>
        <li>Contribute to brainstorming sessions and help generate new ideas for marketing strategies.</li>
    </ul>
    <p></p>
<strong><font color="#2272FF">Qualifications:</font></strong>
<p></p>
    <ul>
        <li>Currently pursuing or recently completed a degree in Marketing, Communications, Business, or a related field.</li>
        <li>Strong written and verbal communication skills in English.</li>
        <li>Familiarity with social media platforms, content creation, and digital marketing tools.</li>
        <li>Creative mindset with a keen eye for detail and design.</li>
        <li>Ability to work independently and as part of a team.</li>
        <li>Passionate about technology, AI, and staying up-to-date with industry trends.</li>
    </ul>
<p></p>
<strong><font color="#2272FF">A Plus:</font></strong>
<p></p>
    <ul>
        <li>Experience with graphic design tools such as Canva, Adobe Creative Suite, or similar.</li>
        <li>Basic understanding of SEO, Google Analytics, and online advertising.</li>
    </ul>
<p></p>    
<strong><font color="#2272FF">What We Offer:</font></strong>
<p></p>
    <ul>
        <li>Hands-on experience in a fast-growing tech company.</li>
        <li>Mentorship and professional development opportunities.</li>
        <li>A supportive work environment.</li>
    </ul>

<div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
<span class="vc_sep_holder vc_sep_holder_l">
    <span class="vc_sep_line"></span>
</span>
<span class="vc_sep_holder vc_sep_holder_r">
    <span class="vc_sep_line"></span>
</span>
</div>
</div>

<Link to="/jobs/process" class="btn btn-warning">How To Apply</Link>


    </>
    )
  };
  export default JobsMarketingIntern;