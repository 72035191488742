
import { Link } from "react-router-dom";
import { Seo } from '../layouts/Seo';

const About = () => {
    return (
    <>


  <Seo
        title="About Saigon A.I. - Our Story, Culture, Working Process, Leadership and Press"
        description="Since its inception in 2019, the company has been at the forefront of leveraging its business acumen and culture of innovation to provide valuable insights to other businesses. The team at Saigon A.I. is dedicated to assisting companies in defining their markets and identifying their inventive pace, so that they can better equip themselves to stand out and succeed in an ever-more-complex market.  By delivering on-demand A.I. solutions, Saigon A.I. helps its clients achieve greater efficiency, productivity, and competitiveness, ultimately paving the way for long-term success."
        type="webapp"
      />

<div class="breadcrumb">
    <span class="caret">&gt;</span>
    <span class="current">About Us</span>
</div>

<h2 class="featurette-heading">Saigon A.I. <span class="text-muted">What you need to know.</span></h2>
<div>
  <Link to="/about/story" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/story">Our Story</Link> 
  <Link to="/about/process" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/process">Working Process</Link> 
  <Link to="/about/culture" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/culture">Culture</Link> 
  <Link to="/about/leadership" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/leadership">Leadership Team</Link> 
  <Link to="/learn" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/learn">Learning As a Lifestyle</Link> 
  <Link to="/press" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/learn">Press and Media</Link> 
</div>
<br/>

<div class="row featurette">
  <div class="col-md-7">
    <p class="lead">
      Since its inception in 2019, the company has been at the forefront of leveraging its business acumen and culture of innovation to provide valuable insights to other businesses. The team at Saigon A.I. is dedicated to assisting companies in defining their markets and identifying their inventive pace, so that they can better equip themselves to stand out and succeed in an ever-more-complex market.
    </p>
    <p class="lead">
    <p>Our team can help you solve critical business problems and accelerate both innovation and delivery for your data and A.I. projects.
            We are flexible to work with our clients how and where they need help, working on project or term basis, and we can scale as your needs change.
            
      Our 20+ years of experience working with SME and Fortune 100 customers, western management team, and <Link to="/about/process">proven working process</Link> result in a working culture that both inspires and accelerates innovation.</p>
    </p>

      <p>
    Read more about our <Link to="/solutions">solutions</Link> and <Link to="/capabilities">capabilities</Link>.
    </p>


  </div>
  <div class="col-md-5">
    <img src="/img/about_us.jpg" class="max-h-200 roundy" alt="About our Team" />
  </div>
</div>  

    </>
    )
  };
  
  export default About;