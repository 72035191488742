import { Link } from "react-router-dom";

const JobsApplicationProcess = () => {
    return (
    <>

    <div class="breadcrumb">
        <span class="caret">&gt;</span>
        <span class="link"><Link to="/jobs">Work Opportunities</Link></span>
        <span class="caret">&gt;</span>
        <span class="current">Application Process</span>
    </div>

    <h1>Application Process</h1>

  
    <div class="wpb_text_column wpb_content_element ">
    <div class="wpb_wrapper">
        <h4>
            <font color="#2272FF">
                <b>1. Send your application files</b>
            </font>
        </h4>
        <p>
            Make sure your application documents are well prepared and send them to &nbsp; 
            <strong><Link to="mailto:hr@saigonai.com">hr@saigonai.com</Link></strong>
        </p>
        <p>If you are applying for employment, please provide:</p>
        <ul>
            <li>Your CV</li>
            <li>Your cover letter</li>
        </ul>
        <p>If you are a student or recent graduate and looking for an internship, please provide:</p>
        <ul>
            <li>Your CV</li>
            <li>Your cover letter</li>
            <li>Your transcript</li>
        </ul>
        <p>
            <em>
                <strong>Note that we only accept documents in English.</strong>
            </em>
        </p>

    </div>
</div>
<div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
    <span class="vc_sep_holder vc_sep_holder_l">
        <span class="vc_sep_line"></span>
    </span>
    <span class="vc_sep_holder vc_sep_holder_r">
        <span class="vc_sep_line"></span>
    </span>
</div>
<div class="wpb_text_column wpb_content_element ">
    <div class="wpb_wrapper">
        <h4>
            <font color="#2272FF">
                <b>2. The first interview</b>
            </font>
        </h4>
        <p>Your application files will be examined by our HR staff and then specialized department. If your profile meets the position&#8217;s requirements, the HR staff will contact you for the 1st interview.</p>
        <p>
            <strong>Who will interview you? </strong>
            Normally, we will have at least three interviewers: an HR representative, a specialist from the department you applied and a manager.
        </p>

    </div>
</div>
<div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
    <span class="vc_sep_holder vc_sep_holder_l">
        <span class="vc_sep_line"></span>
    </span>
    <span class="vc_sep_holder vc_sep_holder_r">
        <span class="vc_sep_line"></span>
    </span>
</div>
<div class="wpb_text_column wpb_content_element ">
    <div class="wpb_wrapper">
        <h4>
            <font color="#2272FF">
                <b>3. Complete an assignment</b>
            </font>
        </h4>
        <p>If you pass the first interview, we will soon contact you by email with the result and typically we will give you a technical and writing assignment.</p>
        <p>
            <strong>Why do we need that? </strong>
            The purpose of technical and writing assignment is not to judge your answers, but rather to help us better understand your thinking, working process, and how you solve the problems. 
        </p>

    </div>
</div>
<div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
    <span class="vc_sep_holder vc_sep_holder_l">
        <span class="vc_sep_line"></span>
    </span>
    <span class="vc_sep_holder vc_sep_holder_r">
        <span class="vc_sep_line"></span>
    </span>
</div>
<div class="wpb_text_column wpb_content_element ">
    <div class="wpb_wrapper">
        <h4>
            <font color="#2272FF">
                <b>4. Second interview</b>
            </font>
        </h4>
        <p>After receiving your assignment submission, we will invite you to the second interview where we will go into more detail with some technical questions as well as how you can start your job at Saigon A.I.</p>
        <p>After the second interview, we will make a final decision and send an official announcement via email to you.</p>

    </div>
</div>
<div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
    <span class="vc_sep_holder vc_sep_holder_l">
        <span class="vc_sep_line"></span>
    </span>
    <span class="vc_sep_holder vc_sep_holder_r">
        <span class="vc_sep_line"></span>
    </span>
</div>
<div class="wpb_text_column wpb_content_element ">
    <div class="wpb_wrapper">
        <h4>If you have any questions regarding the application process, feel free to reach out to us at &nbsp;<strong><Link to="mailto:hr@saigonai.com">hr@saigonai.com</Link></strong>. Join our team and together we can make a bigger impact on the world!</h4>

    </div>
</div>

    </>
    )
  };
  
  export default JobsApplicationProcess;