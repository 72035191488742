import { Link } from "react-router-dom";

import "./Process.css";
import { Seo } from '../layouts/Seo';

const Process = () => {
    return (
    <>

    <Seo
        title="Saigon A.I. - Working Process - How We Boost Your Innovation"
        description="Project Based Micro Teams; Western-managed approach; prioritize collaboration and knowledge sharing;"
        type="webapp"
      />

        <div class="breadcrumb">
            <span class="caret">&gt;</span>
            <span class="link"><Link to="/about">About Us</Link></span>
            <span class="caret">&gt;</span>
            <span class="current">Process</span>
        </div>
        <h1>How We Work</h1>

        <div class="row featurette">
            <div class="col-md-7 order-md-2">
                <h2 class="featurette-heading">Project Based <span class="text-muted">Micro Teams.</span></h2>
                <p class="lead">
                    At Saigon A.I., our work philosophy revolves around a Western-managed approach, ensuring efficiency and alignment with international standards. We cultivate a culture of perpetual learning, emphasizing continuous training to keep our skills sharp and current.
                </p>
                <p>Operating in micro-teams rather than per-seat arrangements, we prioritize collaboration and knowledge sharing, preventing the loss of valuable project experience. Whether undertaking full projects or seamlessly integrating our expertise to bolster your existing team, we adapt to your specific needs.</p>    
                <p>Our senior experts are always ready to jump into any project, offering their wealth of experience and insights as needed. This flexible and collaborative working model defines our commitment to delivering high-quality results while fostering a culture of continuous improvement and shared success.</p>
            </div>
            <div class="col-md-5 order-md-1">
                <img class="feature-image" alt="Saigon A.I. Micro Teams" src="/img/micro_team.jpg" />
            </div>
        </div>

        <div class="py-1">&nbsp;</div>

        <div class="row">
            <div class="col-md-1 py-3">&nbsp;</div>
            <div class="col-md-9 py-4 px-3 quote">
                <div class="quotetext">
                “We have worked with Saigon A.I. for the last two years. We have been impressed with the breadth and depth of their skills, and the flexibility to deliver services from diligent desk research to complex predictive modeling.”
                </div>
                <div class="quotecredit">- Global Head of Sales & Channel Analytics at HP</div>
            </div>
        </div>

        <div class="py-3">&nbsp;</div>

    </>
    )
  };
  
  export default Process;