import { Link } from "react-router-dom";


const DataAudit = () => {
    return (
    <>

        <div class="breadcrumb">
            <span class="caret">&gt;</span>
            <span class="link"><Link to="/capabilities">Capabilities</Link></span>
            <span class="caret">&gt;</span>
            <span class="current">Data Auditing</span>
        </div>
        <h2 class="featurette-heading">Data Auditing: <span class="text-muted">Impartial evaluation.</span></h2>


        <p class="lead">
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/story">Data Drift</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/story">Overfitting</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/story">Feature Importance</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/story">Decision Explanation</Link> 
        </p>


    <div class="row featurette">
      <div class="col-md-7">
        <p class="lead">
          Our team is proficient at ensuring the accuracy, consistency, and integrity of datasets through thorough validation, refining the underlying infrastructure to optimize performance, and addressing the challenges posed by data drift, evolving data patterns over time. By incorporating model testing methodologies, we guarantee the reliability and effectiveness of analytical models, contributing to the overall quality and reliability of data-driven decision-making processes. Our commitment to excellence positions us as trusted partners in enhancing the efficiency and reliability of data processes for our clients.
        </p>
          <div>Some of our clients use this service from these industries:</div>
          <ul>
            <li>Computer Hardware</li>
            <li>Financial</li>
            <li>Healthcare</li>
            <li>Insurance</li>
          </ul>

          <div>
            To discuss your project and how we can help &nbsp;
            <Link to="/contact" class="btn btn-sm btn-primary" href="#contact" onclick="load_page(this)" data-content="content/contact">Contact us</Link>

          </div>

      </div>
      <div class="col-md-5">
        <img src="/img/data_process_auditing.png" class="max-px-300 roundy" alt="Data Auditing" />
      </div>
    </div>

    <div class="py-3">&nbsp;</div>



    </>
    )
  };
  
  export default DataAudit;