import { Link } from "react-router-dom";

const JobsKeyThings = () => {
    return (
    <>

    <div class="breadcrumb">
        <span class="caret">&gt;</span>
        <span class="link"><Link to="/jobs">Work Opportunities</Link></span>
        <span class="caret">&gt;</span>
        <span class="current">Key Things To Know Before You Apply</span>
    </div>

    <h1>Key Things To Know Before You Apply</h1>

  
    <div class="wpb_text_column wpb_content_element ">
    <div class="wpb_wrapper">

    <strong>Core Skills:</strong>
    <p></p>
    <ul>
        <li><strong><font color="#2272FF">Critical thinking skills.</font></strong> Learn how to evaluate a new problem and break it down into smaller pieces.</li>
        <li><strong><font color="#2272FF">Learn balance with self-learning.</font></strong> When to stop self-research and ask your mentor, when to do self-research instead of asking your mentor which will minimize wasted time.</li>
        <li><strong><font color="#2272FF">Be teachable.</font></strong> "You don't know what you don't know."</li>
    </ul>
<p></p>
    <strong>Responsibilities:</strong>
    <p></p>
    <ul>
        <li><strong><font color="#2272FF">Come ready to learn.</font></strong> Even if you have studied data science in university, application of that knowledge is key and harder than you may expect. Come with an open mind ready to learn new things, not with an attitude that you already know it.</li>
        <li><strong><font color="#2272FF">Self-learning skills are very important.</font></strong></li>
        <li><strong><font color="#2272FF">Be proactive.</font></strong> Don't just sit around waiting for direction.</li>
        <li><strong><font color="#2272FF">Learn from everyone.</font></strong> "Everyone is better at something than you". We work as a team which means everyone learns from each other.</li>
        <li><strong><font color="#2272FF">Listen to everyone.</font></strong> Respect everyone - "We are all 18-years-old".</li>
        <li><strong><font color="#2272FF">Company culture is the most important thing to understand.</font></strong> Our team is close-knit. We work hard but also enjoy time together.</li>
    </ul>
<p></p>
    <strong>Expectations:</strong>
    <p></p>
    <ul>
        <li>Participation in Thursday morning "Coffee Talk"</li>
        <li>Help wherever you can which includes items not in the job description</li>  
    </ul>


  </div>
  </div>
<div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
    <span class="vc_sep_holder vc_sep_holder_l">
        <span class="vc_sep_line"></span>
    </span>
    <span class="vc_sep_holder vc_sep_holder_r">
        <span class="vc_sep_line"></span>
    </span>
</div>
<div class="wpb_text_column wpb_content_element ">
    <div class="wpb_wrapper">
        If you have any questions, feel free to reach out to us at <a href="mailto:hr@saigonai.com">hr@saigonai.com</a>. Join our team and together we can make a bigger impact on the world!

    </div>
</div>

    </>
    )
  };
  
  export default JobsKeyThings;