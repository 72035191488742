import { Seo } from '../layouts/Seo';
import { Link } from "react-router-dom";
import React, { useEffect, useRef } from "react";

import "./NoPage.css";

const NoPage = () => {

  const js_load_ref = useRef();

  useEffect(() => {

    if (!js_load_ref.current) {
      js_load_ref.current = true;

      const script = document.createElement('script');
      script.src = "/js/pacman.js";  // from public folder
      script.async = true;  
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      }

    }
  }, []);

  return (
      <>      

    <Seo
        title="Saigon A.I. - A.I. Advisory Company"
        description=""
        type="webapp"
      />


    <div class="breadcrumb">
        <span class="caret">&gt;</span>
        <span class="link"><Link to="/">I'm Lost, Lets Start Over</Link></span>
    </div>

      <div class="py-4">
        <h2>404 Not Found : Have You Lost Your Way?</h2>
        </div>

        <div class="py-2">
        <p>Broken links happen to the best of us.  Sometimes taking a different path has its own reward.</p>
        </div>

        <div id="page">
           <canvas id="canvas" class="game404" width="607" height="405"></canvas>
        </div>

      </>
    );
  };
  
  export default NoPage;