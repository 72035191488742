import { Link } from "react-router-dom";
import { Seo } from '../layouts/Seo';

const Learn = () => {
    return (
    <>

<Seo
        title="Saigon A.I. - Learning - Education at our company"
        description="Lifelong learning"
        type="webapp"
      />

<div class="breadcrumb">
    <span class="caret">&gt;</span>
    <span class="link"><Link to="/about" onclick="load_page(this)" data-content="content/about">About Us</Link></span>
    <span class="caret">&gt;</span>
    <span class="current">Learning</span>
</div>

<h2 class="featurette-heading">You never stop learning. <span class="text-muted">Reinvent Yourself.</span></h2>
<div class="row featurette">
    <div class="col-md-8 order-md-2">
        <p class="lead">

            At our company, we embrace the belief that learning should be a lifelong pursuit, an essential philosophy applicable to all careers. 
            Recognizing the transformative power of knowledge, we encourage continuous learning through our academy courses and targeted training 
            sessions. We go beyond the conventional by requiring our staff to write several algorithms from scratch.  
          </p>
          <p>Moreover, we support and 
            advocate for higher education pursuits, understanding that an enriched educational background contributes not only to personal growth 
            but also to the collective expertise of our team. By placing learning at the forefront of our professional journey, we aim to empower 
            our employees with the skills and insights necessary to thrive in their careers and contribute meaningfully to our dynamic work environment.
        </p>
    </div>
    <div class="col-md-4">
      <img src="/img/vietnamese_studying.jpg" class="max-w-100 roundy" alt="Culture of Learning"/>
    </div>
  </div>

  <div class="row featurette">
    <div class="col-md-7">
      <h2 class="featurette-heading">I.T. <span class="text-muted">English Club.</span></h2>
      <p class="lead">To encourage ongoing education and equip individuals for employment in international companies, we offer a free I.T. English Club, held monthly. This club serves as an invaluable resource for participants seeking to enhance their English language proficiency within the context of Information Technology, a crucial skill set in today's globalized workplace.</p>
    </div>
    <div class="col-md-5">
      <div class="col-md-5">
        <img src="/img/english_club.jpeg" class="max-h-200 roundy"  alt="English Club" />
      </div>
      </div>
  </div>



    </>
    )
  };
  
  export default Learn;