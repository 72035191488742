import { Link } from "react-router-dom";


const DataAnalysis = () => {
    return (
    <>

        <div class="breadcrumb">
            <span class="caret">&gt;</span>
            <span class="link"><Link to="/capabilities">Capabilities</Link></span>
            <span class="caret">&gt;</span>
            <span class="current">Data Analysis</span>
        </div>
        <h1>Data Analysis</h1>

        <div>
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/story">Extracting Data</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/learn">Exploratory Data Analysis (EDA)</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/process">Analyzing Results</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/process">Storytelling from Data</Link> 
        </div>

    <div class="row featurette">
        <div class="col-md-7">
          <h2 class="featurette-heading">Finding the Truth<span class="text-muted"> in the Data.</span></h2>
          <p class="lead">Data analysis services play a pivotal role in extracting valuable insights and actionable information from vast datasets. We utilize advanced analytical tools and techniques to interpret complex data, uncovering patterns, trends, and correlations that empower businesses to make informed decisions. From market research and customer behavior analysis to performance metrics evaluation, our team contributes to optimizing operational efficiency and strategic planning.</p>
          <div>
            To discuss your project and how we can help &nbsp;
            <Link to="/contact" class="btn btn-sm btn-primary" href="#contact" onclick="load_page(this)" data-content="content/contact">Contact us</Link>

          </div>
        </div>
        <div class="col-md-5">
          <img src="/img/data_analysis.png" class="max-px-300 roundy" alt="data analysis"/>
        </div>
      </div>
  
      <div class="py-3">&nbsp;</div>



    </>
    )
  };
  
  export default DataAnalysis;