import { Link } from "react-router-dom";

const NaturalLanguage = () => {
    return (
    <>

        <div class="breadcrumb">
            <span class="caret">&gt;</span>
            <span class="link"><Link to="/capabilities">Capabilities</Link></span>
            <span class="caret">&gt;</span>
            <span class="current">Natural Language Processing</span>
        </div>
        <h1>Natural Language Processing</h1>

<div>
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/story">Virtual Assistants</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/learn">Predictive Text</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/process">Sentiment Analysis</Link> 
</div>

    <div class="row featurette">
        <div class="col-md-7">
          <h2 class="featurette-heading">Textual <span class="text-muted">Data Analysis.</span></h2>
          <p class="lead">
            Proficient in deciphering the nuances of human language, our team excels in developing and implementing advanced algorithms 
            that enable accurate sentiment analysis across diverse textual data. Whether it's understanding customer feedback, gauging 
            public opinion, or extracting insights from vast datasets, our team's proficiency in <strong>NLP and sentiment analysis</strong> (labeling text as positive, negative, or neutral) empowers 
            businesses to derive valuable information, make informed decisions, and enhance user experiences. 
            
            Since we have a culture of lifetime learning, we are trying new solutions and are happy to discuss your project requirements.</p>

            <div>Some of our clients use this service from these industries:</div>
            <ul>
              <li>Computer Hardware</li>
            </ul>

            <div>
              To discuss your project and how we can help  &nbsp;
              <Link to="/contact" class="btn btn-sm btn-primary" href="#contact" onclick="load_page(this)" data-content="content/contact">Contact us</Link>

            </div> 
          </div>
        <div class="col-md-5">
          <img src="/img/nlp_image.png" class="max-px-300 roundy" alt="NLP"/>
        </div>
      </div>
  
      <div class="py-3">&nbsp;</div>




    </>
    )
  };
  
  export default NaturalLanguage;