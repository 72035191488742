import { Link } from "react-router-dom";

const GenerativeAI = () => {
    return (
    <>

        <div class="breadcrumb">
            <span class="caret">&gt;</span>
            <span class="link"><Link to="/capabilities">Capabilities</Link></span>
            <span class="caret">&gt;</span>
            <span class="current">Generative A.I.</span>
        </div>
        <h1>Generative A.I.</h1>

<div>
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/story">ChatGPT / LLMs</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/learn">Prompt Engineering</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/process">Midjourney</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/story">BlueWillow</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/learn">Stable Diffusion</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/process">Dall-E</Link> 
</div>

    <div class="row featurette">
        <div class="col-md-7">
          <h2 class="featurette-heading">Put Generative A.I. <span class="text-muted">to Work For Your Business</span></h2>
          <p class="lead">
            For organizations seeking to leverage the power of generative A.I. solutions, we understand that the journey may seem daunting. Whether you require expert guidance, personalized consulting services, or simply need assistance in initiating your generative A.I. project, we are here to help. Our team is dedicated to providing the support you need to navigate the complexities of implementation. <a href = "contact.html">Contact us</a> to embark on a collaborative journey where we can tailor solutions to meet your specific requirements, ensuring a seamless and successful integration of generative A.I. into your business processes.
          </p>
          <div>
            To discuss your project and how we can help &nbsp;
            <Link to="/contact" class="btn btn-sm btn-primary" href="#contact" onclick="load_page(this)" data-content="content/contact">Contact us</Link>

          </div>
        </div>
        <div class="col-md-5">
          <img src="/img/genai_hand_wave.jpg" class="max-px-300 roundy"alt="Generative AI"/>
        </div>
      </div>


    </>
    )
  };
  
  export default GenerativeAI;