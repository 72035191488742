import { Link } from "react-router-dom";


const AIFirst = () => {
    return (
    <>

        <div class="breadcrumb">
            <span class="caret">&gt;</span>
            <span class="link"><Link to="/solutions">Solutions</Link></span>
            <span class="caret">&gt;</span>
            <span class="current">A.I. First Advisory Services</span>
        </div>
        <h1>A.I. First Advisory Services</h1>


<div class="row featurette">
    <div class="col-md-7">
      <h2 class="featurette-heading"><span class="text-muted">Thinking A.I. For All Your Initiatives</span></h2>
      <p class="lead">Since A.I. is here to stay, and is moving so quickly, adopting an "A.I. First" approach to your company lets you proactively grow using the right tech to boost efficiency.</p>

      <h2 class="featurette-heading">KPIs <span class="text-muted">and Evaluation Frameworks.</span></h2>
          <p class="lead">From transcription agents to personal assitants to generative AI for content and media, the tools may seem overwhelming.  
            Saigon A.I. is your trusted partner to help your organization select, pilot and roll out AI tools.  And if the tools you need aren't available yet in the market, our design-thinking team can help to spec and build it.</p>

          <div>Some of our clients use this service from these industries:</div>
          <ul>
            <li>Computer Hardware</li>
            <li>Finance</li>
            <li>Heathcare</li>
            <li>Insurance</li>
            <li>Mobile Food & Beverage</li>
          </ul>

      <div>
        To discuss your project and how we can help &nbsp;
        <Link to="/contact" class="btn btn-sm btn-primary" href="#contact" onclick="load_page(this)" data-content="content/contact">Contact us</Link>
      </div>
    </div>
    <div class="col-md-5">
      <img src="/img/ai_design.jpeg" class="max-px-300 roundy" alt="AI And Machine Learning"/>
    </div>
  </div>

  <div class="py-3">&nbsp;</div>


    </>
    )
  };
  
  export default AIFirst;