import { Link } from "react-router-dom";

const Dashboard = () => {
    return (
    <>

        <div class="breadcrumb">
            <span class="caret">&gt;</span>
            <span class="link"><Link to="/capabilities">Capabilities</Link></span>
            <span class="caret">&gt;</span>
            <span class="current">Dashboarding</span>
        </div>
        <h1>Dashboarding</h1>

    <div>
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/story">Tableau</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/learn">AWS Quicksight</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/process">Power BI</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/culture">Jupyter</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/leadership">Web Apps</Link> 
    </div>

    <h2 class="featurette-heading">Storytelling<span class="text-muted"> with Data.</span></h2>

    <div class="row featurette">
        <div class="col-md-7">
          <p class="lead">
            Dashboards offer a streamlined and visual representation of key performance indicators and critical data metrics, providing businesses with a comprehensive and accessible overview of their operations. These dashboards serve as centralized hubs, aggregating real-time information from various sources and presenting it in a user-friendly format. Equipped with intuitive graphics and charts, dashboard services facilitate quick and informed decision-making by offering at-a-glance insights into performance trends, project statuses, and other relevant metrics. Whether tracking sales performance, monitoring website analytics, or assessing financial data, dashboards empower users to monitor and analyze complex information efficiently. With customizable features and interactive elements, dashboards enhance transparency, collaboration, and strategic planning within organizations, fostering a data-driven culture for better decision outcomes.
            </p>
          
            <div>Some of our clients use this service from these industries:</div>
            <ul>
              <li>Computer Hardware</li>
              <li>Heathcare</li>
              <li>Insurance</li>
            </ul>
  
            <div>
              To discuss your project and how we can help &nbsp;
              <Link to="/contact" class="btn btn-sm btn-primary" href="#contact" onclick="load_page(this)" data-content="content/contact">Contact us</Link>
            </div>

        </div>
        <div class="col-md-5">          
          <img src="/img/dashboarding.png" class="max-px-300 roundy" alt="Dashboarding"/>
        </div>
      </div>
  
  
      <div class="py-3">&nbsp;</div>


    </>
    )
  };
  
  export default Dashboard;