
import { Link } from "react-router-dom";
import { Seo } from '../layouts/Seo';

const Story = () => {
    return (
    <>

  <Seo
        title="Saigon A.I. - Story - History of Our Team"
        description="The formal establishment of Saigon A.I. in 2019 marked the beginning of a journey that has seen us collaborate with teams across the globe. "
        type="webapp"
      />

<div class="breadcrumb">
    <span class="caret">&gt;</span>
    <span class="link"><Link to="/about" onclick="load_page(this)" data-content="content/about">About Us</Link></span>
    <span class="caret">&gt;</span>
    <span class="current">Our Story</span>
  </div>


  <div class="row featurette">
  <div class="col-12">
      <h3 class="featurette-heading">Empowering Vietnam's Tech Renaissance: <br/><span class="text-muted">A Journey of Innovation and Impact .</span></h3>
        <p class="lead">
          <img class="feature-image max-px-300 roundy pull-right" src="/img/neural_network_from_scratch.jpeg"  alt="Saigon A.I. Workshop on Neural Network coding from scratch"/>

          In 2016, our founders embarked on a mission to contribute to Vietnam's burgeoning international technology standing. 
          Their objective was clear: to elevate Vietnam's profile by offering cutting-edge data science to international companies 
          and teach design-thinking for Artificial Intelligence. Timing and research have proven to be correct, as the technological 
          landscape in Vietnam, particularly in Ho Chi Minh City, was on the brink of a significant boom.         
        </p>
    </div>
  </div>

  <div class="row featurette">
    <div class="col-12">
      <h4 class="featurette-heading" >Sustainable Growth.</h4>
      <p class="lead"> 
        The formal establishment of Saigon A.I. in 2019 marked the beginning of a journey that has seen us collaborate with teams across the globe. Our focus extends to enhancing business decision-making processes, simplifying home healthcare, and driving innovation in customer products and services. As we navigate the dynamic changing landscape of A.I., we remain grateful to our clients for their pivotal role in helping us establish Vietnam as a global hub for innovation.
      </p>
    </div>
  </div>
  

<div class="row featurette">
  <div class="col-12">
  <img class="feature-image max-px-300 roundy pull-right" src="/img/ho_chi_minh_city.jpg"  alt="Ho Chi Minh City"/>
  <h2 class="featurette-heading">Our Mission. <span class="text-muted">Not Easy, But Important.</span></h2>
    <p class="lead">
    
    Saigon A.I. is committed to pioneering Vietnam's technological evolution by providing cutting-edge data science solutions to international enterprises. Our mission is to elevate Vietnamese in the technology sector through innovation, excellence, and a steadfast dedication to teaching design-thinking for Artificial Intelligence. We strive to contribute to the vibrant landscape of Ho Chi Minh City and beyond, fostering a community of forward-thinking professionals who shape the future of technology.</p>
  </div>
</div>

<div class="row featurette">
  <div class="col-12 order-md-2">
    <h2 class="featurette-heading">Awards and  <span class="text-muted">Recognition.</span></h2>
      <p class="lead">
      <img class="feature-image pull-left max-h-200 roundy" src="/img/amcham_award.jpg" alt="AmCham Awards" />
      Saigon A.I. has earned notable recognition for its commendable social impact by AmCham for two consecutive years and earning esteemed placements in prestigious rankings such as Asia's Top 20 Best Startups and Futurologies' list of Top Innovated Machine Learning Companies. Our commitment to nurturing the future workforce is evident through strategic partnerships with institutions like the University of Economics Ho Chi Minh City and others. These collaborations aim to empower students with the skills and insights needed for future careers in the rapidly evolving landscape of artificial intelligence. Saigon A.I. is proud to play a pivotal role in shaping the next generation of tech professionals and contributing to the socio-economic development of the region.        
      </p>
  </div>
</div>


    </>
    )
  };
  
  export default Story;