import { Link } from "react-router-dom";

const JobsDataAnalyst = () => {
    return (
    <>

    <div class="breadcrumb">
        <span class="caret">&gt;</span>
        <span class="link"><Link to="/jobs">Work Opportunities</Link></span>
        <span class="caret">&gt;</span>
        <span class="current">Data Analyst</span>
    </div>

    <h1>Data Analyst</h1>

    <div class="wpb_wrapper">

<strong><font color="#2272FF">Position Summary:</font></strong> We are looking for an experienced Data Analyst to join our team. The ideal candidate will have acquired skills of data analysis including interpreting data, analyzing and performing the results comprehensively. 
<p></p>
<strong><font color="#2272FF">Key Responsibilities:</font></strong><p></p>
    <ul>
        <li>Collect, clean, and preprocess data from various sources to ensure accuracy and completeness. </li>
        <li>Analyze complex datasets to identify trends, patterns and insights. </li>
        <li>Create and maintain dashboards and reports to visualize data and deliver findings comprehensively. </li>
        <li>Build and maintain machine learning models. </li>
        <li>Communicate with managers to understand business requirements. Collaborate with co-workers to work on different projects. </li>
    </ul>
<p></p>
<strong><font color="#2272FF">Qualifications:</font></strong><p></p>
    <ul>
        <li>Bachelor's degree in Data Science, Statistics, Computer Science, Economics, International Business or a related field.</li>
        <li>Minimum of 1 year experience in data analysis (Internships do not count as work experience).</li>
        <li>Proficiency in using data analysis programming languages such as SQL, Python, R, or similar.</li>
        <li>Experience with data visualization tools like Tableau, Power BI, or similar.</li>
        <li>Strong analytical skills with the ability to interpret complex data sets.</li>
        <li>Active communication skills.</li>
        <li>Open-minded and adaptable to the work environment and company culture.</li>
        <li>Self motivation to keep up with new trends and learn new tech.</li>
        <li>Ability to work independently to research solutions and work in a team to find the best solutions for the customer.</li>
    </ul>
<p></p>
<strong><font color="#2272FF">As a Plus:</font></strong><p></p>
    <ul>
        <li>Experience with machine learning techniques and frameworks.</li>
        <li>Knowledge of cloud platforms (e.g., AWS, Google Cloud, Azure).</li>
        <li>Familiar with big data technologies (e.g., Hadoop, Spark).</li>
    </ul>
<p></p>
<strong><font color="#2272FF">What We Offer</font></strong><p></p>
    <ul>
        <li>Opportunities for professional development and career growth.</li>
        <li>Collaborative and innovative work environment.</li>
        <li>Access to cutting-edge technology and resources.</li>
        <li>On-site English training. </li>
    </ul>
<p></p>

<div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
<span class="vc_sep_holder vc_sep_holder_l">
    <span class="vc_sep_line"></span>
</span>
<span class="vc_sep_holder vc_sep_holder_r">
    <span class="vc_sep_line"></span>
</span>
</div>
</div>

<Link to="/jobs/process" class="btn btn-warning">How To Apply</Link>


    </>
    )
  };
  export default JobsDataAnalyst;