import { Link } from "react-router-dom";

import "./Process.css";
import { Seo } from '../layouts/Seo';

const Process = () => {
    return (
    <>

    <Seo
        title="Saigon A.I. - Solutions"
        description=""
        type="webapp"
      />

        <div class="breadcrumb">
            <span class="caret">&gt;</span>
            <span class="current">Solutions</span>
        </div>
        <h1>What We Do</h1>


        


        <p>Saigon A.I. is an advisory company for organizations working on data-driven projects, offering design and support services 
            for <Link to="/capabilities/data_analysis">data analysis</Link>,&nbsp;
             <Link to="/capabilities/data_analysis">data science</Link>,&nbsp; 
             <Link to="/capabilities/dataengineering">data engineering</Link>&nbsp;
             and <Link to="/capabilities/ai_ml_modeling">machine learning/A.I. modeling</Link>.
             Read more about <Link to="/about/">who we are</Link> and our <Link to="/capabilities">capabilities</Link>.
             </p>  
            

        <h3>Latest Use Cases</h3>

        <div class="container">
            <div class="row">
                <div class="col-md-3 m-2 card">
                        <div class="card-body">
                            <h5 class="card-title">Logistics Inventory Prediction</h5>
                            <p class="card-text">Our analysts have years of experience with sales and inventory modeling for predicting your peak sales and delivery times.</p>
                        </div>
                </div>

                <div class="col-md-3 m-2 card">
                        <div class="card-body">
                            <h5 class="card-title">Retrieval Augmented Generation</h5>
                            <p class="card-text">We help design, refine and support deployment of LLMs and vector databases for your language model projects.</p>
                        </div>
                </div>

                <div class="col-md-3 m-2 card">
                        <div class="card-body">
                            <h5 class="card-title">Behavioral Data Drift</h5>
                            <p class="card-text">Our skilled team can help implement processes that keep you informed as user behavior changes over time.</p>
                        </div>
                </div>

            </div>
        </div>
        <p>&nbsp;</p>
        <div>
            Reach out to our team to discuss how Saigon A.I. can can help you with these or other use cases. &nbsp;
            <Link to="/contact" class="btn btn-sm btn-primary" href="/contact" onclick="load_page(this)" data-content="content/contact">Contact us</Link>

        </div>





        <div class="py-3">&nbsp;</div>

    </>
    )
  };
  
  export default Process;