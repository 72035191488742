import { Outlet, Link } from "react-router-dom";
import './Layout.css';

const Layout = () => {

  return (
    <>

	<header>
	  <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-black">
	    <div class="container-fluid">
	      <Link to="/" class="navbar-brand" href="#about" id="link-logo" onclick="load_page(this)" data-content="content/about"><img alt="Saigon A.I. Company Limited [Logo]" src="/img/sgai_logo_black.png" width="120"/></Link>
	      <button class="navbar-toggler" type="button" aria-controls="navbarHB" aria-expanded="false" aria-label="Toggle navigation"
		  		data-bs-toggle="collapse" 
				data-bs-target="#navbarHB">
	        <span class="navbar-toggler-icon"></span>
	      </button>
	      <div class="collapse navbar-collapse" id="navbarHB">
	        <ul class="navbar-nav me-auto mb-2 mb-md-0">
	          <li class="nav-item">
	            <a class="nav-link active" aria-current="page" href="/">Home</a>
	          </li>
	          <li class="nav-item">
	            <a class="nav-link" href="https://blog.saigonai.com">Blog</a>
	          </li>
			  <li class="nav-item">
	            <Link to="/press" class="nav-link">Press</Link>
	          </li>
	          <li class="nav-item d-md-none">
	            <Link to="/contact" class="nav-link">Contact</Link>
	          </li>
			</ul>
            <Link to="/contact" class="btn btn-warning d-none d-md-block">Contact</Link>
		  </div>
	    </div>
	  </nav>
	</header>


	<main class="d-flex flex-nowrap">	
		<div class="container d-flex flex-column flex-shrink-0 p-3 ">
		    <div class="row">
		        <div class="col-2">
                    <div class="nav flex-column nav-pills" id="nav-pills" role="tablist" aria-orientation="vertical">                
                        <Link to="/" class="btn btn-sgai-nav" id="v-pills-home-tab" data-toggle="pill"  type="button">
                            <span class="disableSpan d-none d-lg-block" ><i class="fa-fw fa-sm fa-solid fa-house"></i>Start Here</span>
                            <span class="disableSpan d-lg-none" ><i class="fa-fw fa-solid fa-house"></i></span>
                        </Link>
                        <Link to="/solutions" class="btn btn-sgai-nav" id="v-pills-usecase-tab" data-toggle="pill"  type="button" role="tab"  >
                            <span class="disableSpan d-none d-lg-block" ><i class="fa-fw fa-sm fa-solid fa-paper-plane"></i>What We Do</span>
                            <span class="disableSpan d-lg-none" ><i class="fa-fw fa-solid fa-paper-plane"></i></span>
                        </Link>
                        <Link to="/about/process" class="btn btn-sgai-nav" id="v-pills-usecase-tab" data-toggle="pill"  type="button" role="tab"  >
                            <span class="disableSpan d-none d-lg-block" ><i class="fa-fw fa-sm fa-solid fa-coffee"></i>How We Work</span>
                            <span class="disableSpan d-lg-none" ><i class="fa-fw fa-solid fa-coffee"></i></span>
                        </Link>
                        <Link to="/capabilities" class="btn btn-sgai-nav" href="#nav-capabilities" id="v-pills-usecase-tab" data-content="content/capabilities" data-toggle="pill"  type="button" role="tab"  >
                            <span class="disableSpan d-none d-lg-block" ><i class="fa-fw fa-sm fa-solid fa-flask"></i>Capabilities</span>
                            <span class="disableSpan d-lg-none" ><i class="fa-fw fa-solid fa-flask"></i></span>
                        </Link>
                        <Link to="/about/culture" class="btn btn-sgai-nav" href="#nav-culture" id="v-pills-usecase-tab" data-content="content/culture" data-toggle="pill"  type="button" role="tab"  >
                            <span class="disableSpan d-none d-lg-block" ><i class="fa-fw fa-sm fa-solid fa-leaf"></i>Culture</span>
                            <span class="disableSpan d-lg-none" ><i class="fa-fw fa-solid fa-leaf"></i></span>
                        </Link>
                        <Link to="/about/story" class="btn btn-sgai-nav" href="#nav-story" id="v-pills-usecase-tab" data-content="content/story" data-toggle="pill"  type="button" role="tab"  >
                            <span class="disableSpan d-none d-lg-block" ><i class="fa-fw fa-sm fa-solid fa-bicycle"></i>Our Story</span>
                            <span class="disableSpan d-lg-none" ><i class="fa-fw fa-solid fa-bicycle"></i></span>
                        </Link>
                        <Link to="/learn" class="btn btn-sgai-nav" href="#nav-learn" id="v-pills-usecase-tab" data-content="content/learn" data-toggle="pill"  type="button" role="tab"  >
                            <span class="disableSpan d-none d-lg-block" ><i class="fa-fw fa-sm fa-solid fa-university"></i>Learn</span>
                            <span class="disableSpan d-lg-none"><i class="fa-fw fa-solid fa-university"></i></span>
                        </Link>
                        <Link to="/jobs" class="btn btn-sgai-nav" href="#nav-jobs" id="v-pills-usecase-tab" data-content="content/jobs" data-toggle="pill"  type="button" role="tab" >
                            <span class="disableSpan d-none d-lg-block" ><i class="fa-fw fa-sm fa-solid fa-users"></i>Join Our Team</span>
                            <span class="disableSpan d-lg-none" ><i class="fa-fw fa-solid fa-users"></i></span>
                        </Link>
                    </div>
		        </div>
                <div class="col-10">
                    <Outlet />
					<p>&nbsp;</p>
					<p>&nbsp;</p>
                </div>
		    </div>
	    </div>
	</main>	


    <footer class="footer mt-auto py-3 bg-black d-flex flex-column flex-sm-row justify-content-between fixed-bottom">

	    <div class="col-md-4 d-flex align-items-center">
	      <span class="mb-3 mb-md-0 text-body-secondary white d-none d-md-block">
	      &copy; 2018-{(new Date().getFullYear())} Saigon A.I. Company Limited
	      </span>
	    </div>
	
	    <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
	      <li class="ms-3"><a class="text-body-secondary white" href="https://www.linkedin.com/company/saigonai"><i class="fab fa-fw fa-linkedin-in"></i></a></li>
	      <li class="ms-3"><a class="text-body-secondary white" href="https://www.facebook.com/SaigonAi.4U"><i class="fab fa-fw fa-facebook-f"></i></a></li>
	      <li class="ms-3" ><a class="text-body-secondary white" href="mailto:info@saigonai.com"><i class="fa-solid fa-fw fa-envelope"></i>info@saigonai.com</a></li>
	      <li>&nbsp;</li>
	    </ul>	    
	</footer>


    </>
  )
};

export default Layout;