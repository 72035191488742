import { Link } from "react-router-dom";

const JobsPythonCodeReviewer = () => {
    return (
    <>

    <div class="breadcrumb">
        <span class="caret">&gt;</span>
        <span class="link"><Link to="/jobs">Work Opportunities</Link></span>
        <span class="caret">&gt;</span>
        <span class="current">Mid-level Python Engineer - Data Science Team</span>
    </div>

    <h1>Mid-level Python Engineer - Data Science Team</h1>

    <div class="wpb_wrapper">

<strong><font color="#2272FF">Job Description:</font></strong> As a Mid-level Python Engineer within our data science team, you will collaborate closely with our talented data scientists and engineers to ensure that our Python code meets high-quality standards, adheres to best practices, and is well-documented. Your expertise in Python, data science libraries, and code review processes will contribute to the success of our projects and the growth of our team with the potential to grow into a project manager and support position within the data science team.
<p></p>
<strong><font color="#2272FF">Key Responsibilities:</font></strong><p></p>
<ul>
<li><strong><font color="#2272FF">Code Review:</font></strong> Conduct thorough code reviews of Python scripts, notebooks, and data pipelines developed by data scientists and engineers. Ensure code quality, readability, performance, and adherence to coding standards.</li>
<li><strong><font color="#2272FF">Feedback and Guidance:</font></strong> Provide constructive feedback to developers to improve code quality and promote best practices in Python programming and data science.</li>
<li><strong><font color="#2272FF">Documentation:</font></strong> Work with team members to ensure that code is well-documented, including explanations of algorithms, data transformations, and usage instructions.</li>
<li><strong><font color="#2272FF">Codebase Maintenance:</font></strong> Assist in maintaining and optimizing the Python codebase, ensuring it is organized and scalable for future projects.</li>
<li><strong><font color="#2272FF">Collaboration:</font></strong> Collaborate closely with data scientists, data engineers, and other stakeholders to understand project requirements and objectives.</li>
<li><strong><font color="#2272FF">Continuous Learning:</font></strong> Stay up-to-date with the latest developments in Python and data science libraries, sharing knowledge with the team and recommending improvements to our development processes.</li>
<li><strong><font color="#2272FF">Quality Assurance:</font></strong> Assist in implementing and maintaining automated testing frameworks and unit tests to ensure code reliability and stability.</li>
</ul>
<p></p>
<strong><font color="#2272FF">Qualifications:</font></strong><p></p>
<ul>
<li>Bachelor's degree in Computer Science, or a related field.</li>
<li>Strong proficiency in Python programming and experience with popular Python data science libraries (e.g., NumPy, pandas, scikit-learn, TensorFlow, PyTorch, FastAPI, Django, Flask).</li>
<li>Proven experience in code review processes and best practices.</li>
<li>Excellent communication skills and the ability to provide constructive feedback.</li>
<li>Attention to detail and a passion for maintaining code quality.</li>
<li>Experience with version control systems (e.g., Git) is a plus.</li>
<li>Knowledge of data visualization tools (e.g., Matplotlib, Seaborn) is a plus.</li>
</ul>
<p></p>
<strong><font color="#2272FF">Benefits:</font></strong><p></p>
<ul>
<li>Salary will be discussed based on candidates’ experience and ability.</li>
<li>Work in a young, dynamic international company founded by experienced American entrepreneurs.</li>
<li>Health insurance, Public holiday and Annual leave as per Vietnamese Labor law.</li>
<li>Opportunity for growth and training.</li>
<li>Experience an international business environment, which provides opportunities to improve Business English.</li>
<li>Company Retreat once a year for full-time employees.</li>
<li>Experience the unique harmony of Vietnamese - American Culture.</li>
<li>Tea-times, Coffee Talk, I.T. English Club, sports activities, in-house games, etc.</li>
<li>Personal coaching and career orientation.</li>
</ul>
<p></p>

<div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
<span class="vc_sep_holder vc_sep_holder_l">
    <span class="vc_sep_line"></span>
</span>
<span class="vc_sep_holder vc_sep_holder_r">
    <span class="vc_sep_line"></span>
</span>
</div>
</div>

<Link to="/jobs/process" class="btn btn-warning">How To Apply</Link>


    </>
    )
  };
  
  export default JobsPythonCodeReviewer;