import { Link } from "react-router-dom";


const DeepLearning = () => {
    return (
    <>

        <div class="breadcrumb">
            <span class="caret">&gt;</span>
            <span class="link"><Link to="/capabilities">Capabilities</Link></span>
            <span class="caret">&gt;</span>
            <span class="current">Deep Learning</span>
        </div>
        <h1>Deep Learning</h1>

<div>
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/story">Image Classification</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/learn">Speech Recognition</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/process">Big Data</Link> 
</div>

    <div class="row featurette">
        <div class="col-md-7">
          <h4 class="featurette-heading">Solving <span class="text-muted">Complex Problems.</span></h4>
          <p class="lead">From image and speech recognition to natural language processing, our proficiency spans a broad spectrum of applications. Our team consistently delivers innovative solutions that leverage the capabilities of neural networks. Whether it's optimizing processes, predicting trends, or extracting meaningful insights from massive datasets, our team is dedicated to pushing the boundaries of what deep learning can achieve, ensuring our clients benefit from relevant solutions that drive tangible results.</p>
          <div>
            To discuss your project and how we can help &nbsp;
            <Link to="/contact" class="btn btn-sm btn-primary" href="#contact" onclick="load_page(this)" data-content="content/contact">Contact us</Link>

          </div>
        </div>
        <div class="col-md-5">
          <img src="/img/neural_network.jpeg" class="max-px-300 roundy" alt="Deep Learning"/>
        </div>
      </div>
  
      <div class="py-3">&nbsp;</div>




    </>
    )
  };
  
  export default DeepLearning;