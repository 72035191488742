import { Link } from "react-router-dom";

const JobsDevIntern = () => {
    return (
    <>

    <div class="breadcrumb">
        <span class="caret">&gt;</span>
        <span class="link"><Link to="/jobs">Work Opportunities</Link></span>
        <span class="caret">&gt;</span>
        <span class="current">Developer Intern</span>
    </div>

    <h1>Developer Intern</h1>

    <div class="wpb_wrapper">

    <strong><font color="#2272FF">Job Description:</font></strong> 
    <p></p>
    We are looking for a talented and enthusiastic Developer Intern to join our team. This internship is a fantastic opportunity for someone eager to gain hands-on experience in software development. As a Developer Intern at Saigon AI, you will work alongside our experienced engineers on real-world projects, helping to build and refine customer applications and implement AI solutions.
    <p></p>
    <strong><font color="#2272FF">Key Responsibilities:</font></strong>
    <p></p>
    <ul>
        <li>Come ready to learn.</li>    
        <li>Collaborate with the development team to write clean, scalable, and efficient code.</li>
        <li>Participate in code reviews and contribute to improving the overall code quality.</li>
        <li>Troubleshoot and debug applications to optimize performance and resolve issues.</li>
        <li>Document technical processes, software updates, and development progress.</li>
        <li>Stay updated on the latest trends and technologies in software development.</li>
    </ul>
    <p></p>
    <strong><font color="#2272FF">Qualifications:</font></strong>
    <p></p>
    <ul>
        <li>Currently pursuing or recently completed a degree in Computer Science, Software Engineering, or a related field.</li>
        <li>Strong programming skills in one or more languages such as Python, Java, C++, or JavaScript.</li>
        <li>Familiarity with version control systems (e.g., Git) and collaborative development workflows.</li>
        <li>Excellent problem-solving skills and a willingness to learn new technologies.</li>
        <li>Ability to work both independently and within a team environment.</li>
    </ul>
    <p></p>
    <strong><font color="#2272FF">A Plus:</font></strong>
    <p></p>
    <ul>
        <li>Basic understanding of AI, machine learning concepts, and algorithms.</li>
        <li>Knowledge of data structures, algorithms, and software design principles.</li>
        <li>Familiarity with cloud platforms (e.g., AWS, Google Cloud) and containerization tools (e.g., Docker).</li>
    </ul>
    <p></p>
    <strong><font color="#2272FF">What We Offer:</font></strong>
    <p></p>
    <ul>
        <li>Exposure to real-world projects and cutting-edge AI technologies.</li>
        <li>Mentorship from experienced engineers and developers.</li>
        <li>A collaborative and innovative work environment.</li>
    </ul>

<div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
<span class="vc_sep_holder vc_sep_holder_l">
    <span class="vc_sep_line"></span>
</span>
<span class="vc_sep_holder vc_sep_holder_r">
    <span class="vc_sep_line"></span>
</span>
</div>
</div>

<Link to="/jobs/process" class="btn btn-warning">How To Apply</Link>


    </>
    )
  };
  export default JobsDevIntern;