import { Link } from "react-router-dom";


const Behavioral = () => {
    return (
    <>

        <div class="breadcrumb">
            <span class="caret">&gt;</span>
            <span class="link"><Link to="/capabilities">Capabilities</Link></span>
            <span class="caret">&gt;</span>
            <span class="current">Behavioral Data</span>
        </div>
        <h1>Behavioral Data</h1>

    <div>
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/story">Visitor Classification</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/learn">Risk Detection</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/process">Outlier Detection</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/culture">Decisioning</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/leadership">QoS Monitoring</Link> 
    </div>

    <div class="row featurette">
        <div class="col-md-7">
          <h2 class="featurette-heading">Good Vs. <span class="text-muted">Bad User Behavior.</span></h2>
          <p class="lead">
            Behavioral data has risen to become some of the most valuable to understand trends, and classify your users into groups.  Saigon A.I. has in-house expertise with designing behavioral data systems, and we have worked with the leading technology providers around the world.
          </p>
          <p class="lead">
            Behavioral data can provide insights about how to predict good versus bad outcomes while a user is still interacting with your app.  
            This becomes critically valuable for detecting risk and fraud, but also when trying to choose the most appropriate product or service offering based on a user's risk profile, or the right prescriptive offering for a successful upsell.
          </p>
          <div>Some of our clients use this service from these industries:</div>
          <ul>
            <li>Heathcare</li>
            <li>HR</li>
            <li>Finance</li>
          </ul>
          <div>
            To discuss your project and how we can help &nbsp;
            <Link to="/contact" class="btn btn-sm btn-primary" href="#contact" onclick="load_page(this)" data-content="content/contact">Contact us</Link>

          </div>
        </div>
        <div class="col-md-5">
          <img src="/img/behavioral.jpg" class="max-px-300 roundy"  alt="behavioral data" />
        </div>
      </div>


    </>
    )
  };
  
  export default Behavioral;