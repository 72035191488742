import { Link } from "react-router-dom";

const ExplainableAI = () => {
    return (
    <>

        <div class="breadcrumb">
            <span class="caret">&gt;</span>
            <span class="link"><Link to="/capabilities">Capabilities</Link></span>
            <span class="caret">&gt;</span>
            <span class="current">Explainable A.I.</span>
        </div>
        <h1>Explainable A.I.</h1>

   <div>
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/story">Causal Analysis</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/learn">Model Interpretability</Link> 
        <Link to="" class="btn btn-xs btn-outline-light btn-hmar-2" onclick="load_page(this)" data-content="content/process">Neurosymbolic A.I.</Link> 
    </div>


    <div class="row featurette">
      <div class="col-md-7">
        <h2 class="featurette-heading">Accountability. <span class="text-muted">No Black Boxes.</span></h2>
        <p class="lead">
            Machine learning  and A.I. models are being used everywhere, but so many products and services do not have a disiplined approach to measuring the overall quality of predictions.
            Accuracy alone is not enough.  Saigon A.I. has experience with some of the most powerful tools and methodologies for evaluating how and why models arrive at their answers.  
            We can help you implement and monitor a solid methodology to make sure you know your models are performing at peak performance, and to prevent blind spots.
        </p>        
          <div>Some of our clients use this service from these industries:</div>
          <ul>
            <li>Computer Hardware</li>
            <li>Financial</li>
            <li>Healthcare</li>
          </ul>

          <div>
            To discuss your project and how we can help &nbsp;
            <Link to="/contact" class="btn btn-sm btn-primary" href="#contact" onclick="load_page(this)" data-content="content/contact">Contact us</Link>

          </div>

      </div>
      <div class="col-md-5">
        <img src="/img/explainability.jpg" class="max-px-300 roundy" alt="Explainable A.I."  />
        
      </div>
    </div>



    </>
    )
  };
  
  export default ExplainableAI;