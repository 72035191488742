import { Link } from "react-router-dom";

const Leadership = () => {
    return (
    <>

        <div class="breadcrumb">
            <span class="caret">&gt;</span>
            <span class="link"><Link to="/about">About Us</Link></span>
            <span class="caret">&gt;</span>
            <span class="current">Leadership Team</span>
        </div>
        <h1>Leadership Team</h1>

  <div class="container px-4 py-3">
    <div class="row">

        <div class="team-item col-lg-4 col-md-6">
            <div class="team-wrapper">
                <div class="team_photo">
                    <Link to="">
                        <img alt="Rebecca Joy" src="/img/Rebecca.jpg" class="img_team lazyload" />
                        <noscript>
                            <img src="/img/Rebecca.jpg" class="img_team" alt="Rebecca Joy"/>
                        </noscript>
                    </Link>
                </div>
                <div class="team_desc">
                    <div class="name">
                        <Link to="">Rebecca Joy</Link>
                    </div>
                    <span class="team-title">Founder/CEO</span>

                    <div class="team-social">
                        <a href="https://www.facebook.com/rebecca.schwabe.58" class="social-icon">
                            <i class="fa-brands fa-facebook"></i>
                        </a>
                        <a href="https://twitter.com/rebeccaschwabe" class="social-icon">
                            <i class="fa-brands fa-square-x-twitter"></i>
                        </a>
                        <a href="https://www.linkedin.com/in/joyrebecca/" class="social-icon">
                            <i class="fa-brands fa-linkedin"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="team-item col-lg-4 col-md-6">
            <div class="team-wrapper">
                <div class="team_photo">
                    <Link to="">
                        <img alt="Phan Vũ" src="/img/Vu-Phan.jpg" class="img_team lazyload"/>
                        <noscript>
                            <img src="/img/Vu-Phan.jpg" class="img_team" alt="Phan Vũ"/>
                        </noscript>
                    </Link>
                </div>
                <div class="team_desc">
                    <div class="name">
                        <Link to="">Phan Vũ</Link>
                    </div>
                    <span class="team-title">Chief Operating Officer</span>

                    <div class="team-social">
                        <a href="https://www.linkedin.com/in/vu-phan-b99b74266/" class="social-icon">
                            <i class="fa-brands fa-linkedin"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="team-item col-lg-4 col-md-6">
            <div class="team-wrapper">
                <div class="team_photo">
                    <Link to="">
                        <img alt="Andrew Schwabe (Minh)" src="/img/Andrew.jpg" class="img_team lazyload"/>
                        <noscript>
                            <img src="/img/Andrew.jpg" class="img_team" alt="Andrew Schwabe (Minh)"/>
                        </noscript>
                    </Link>
                </div>
                <div class="team_desc">
                    <div class="name">
                        <Link to="">Andrew Schwabe (Minh)</Link>
                    </div>
                    <span class="team-title">Founder/Chairman</span>

                    <div class="team-social">
                        <a href="https://www.linkedin.com/in/aschwabe/" class="social-icon">
                            <i class="fa-brands fa-linkedin"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
  </div>        

    </>
    )
  };
  
  export default Leadership;