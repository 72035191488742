import { Link } from "react-router-dom";

const JobsBizDev = () => {
    return (
    <>

    <div class="breadcrumb">
        <span class="caret">&gt;</span>
        <span class="link"><Link to="/jobs">Work Opportunities</Link></span>
        <span class="caret">&gt;</span>
        <span class="current">Business Development Assistant</span>
    </div>

    <h1>Business Development Assistant</h1>

    <div class="wpb_wrapper">

<strong><font color="#2272FF">Position Summary:</font></strong> Saigon AI is seeking a proactive and organized Business Development Assistant to support C-level management and the business development team in expanding our AI-powered SaaS solutions. With at least 2 years of experience in SaaS or technical product sales, the ideal candidate will assist in market research, lead generation, and the preparation of sales materials. This role involves working closely with internal teams and supporting international business expansion. It's an excellent opportunity to gain exposure to cutting-edge AI technology and contribute to the company's growth in a fast-paced, collaborative environment in Ho Chi Minh City.
<p></p>
<strong><font color="#2272FF">Key Responsibilities:</font></strong><p></p>
    <ul>
        <li>Assist C-level management and the business development team in executing strategies to grow Saigon AI's SaaS business in both local and international markets.</li>
        <li>Research potential clients, gather data on prospects, and assist in identifying new business opportunities. Support demand generation efforts, working closely with the marketing team on campaigns to drive customer interest.</li>
        <li>Help prepare sales presentations, proposals, and contracts, tailored to client needs.</li>
        <li>Assist with market research and competitive analysis to keep the team informed of SaaS and AI trends.</li>
        <li>Organize and maintain business development materials, client information, and performance metrics.</li>
        <li>Coordinate and follow up with clients to ensure smooth communication and successful deal closures.</li>
        <li>Track sales performance and provide reports to C-level management.</li>
    </ul>
<p></p>
<strong><font color="#2272FF">Qualifications:</font></strong><p></p>
    <ul>
        <li>Minimum 2 years of experience in Business Development, Sales, or a similar role.</li>
        <li>Bachelor's degree in International Business or similar field.</li>
        <li>Familiarity with selling SaaS products or technical solutions, with a preference for experience in international markets.</li>
        <li>Strong organizational and multitasking skills with attention to detail.</li>
        <li>Ability to assist in marketing or demand generation activities.</li>
        <li>Strong communication skills in both English and Vietnamese.</li>
        <li>Ability to work collaboratively and support a fast-paced business development team.</li>
        <li>Motivated, with a proactive approach to assisting the business development process.</li>
    </ul>
<p></p>
<strong><font color="#2272FF">What We Offer</font></strong><p></p>
    <ul>
        <li>Salary plus commissions. 100% salary during probation.</li>
        <li>Collaborative and innovative work environment.</li>
        <li>Opportunity for growth and training. </li>
        <li>Opportunities to work with global customers from different cultures.</li>
        <li>Experience an international business environment, which provides opportunities to improve Business English.</li>
        <li>Company Retreat once a year for full-time employees.</li>
        <li>Experience the unique harmony of Vietnamese - American Culture.</li>
        <li>Tea-times, Coffee Talk, I.T. English Club, sports activities, in-house games, etc.</li>
        <li>Working time: Monday - Friday, 8:30AM - 6:00PM.</li>
        <li>Annual Salary review and performance bonus.</li>
        <li>12 days annual leave per year.</li>
        <li>Company-provided MacOS computer, iPad, camera, and earbuds for working at the office.</li>
        <li>Insurance provided in full compliance with the Vietnam Labor Code.</li>
    </ul>
<p></p>

<div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
<span class="vc_sep_holder vc_sep_holder_l">
    <span class="vc_sep_line"></span>
</span>
<span class="vc_sep_holder vc_sep_holder_r">
    <span class="vc_sep_line"></span>
</span>
</div>
</div>

<Link to="/jobs/process" class="btn btn-warning">How To Apply</Link>


    </>
    )
  };
  export default JobsBizDev;