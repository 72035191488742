export const capabilityData = {"nodes": [
    {
      "name": "Data and Process Auditing",
      "href": "dataaudit"
    },
    {
      "name": "Language Models",
      "href": "language_models"
    },
    {
      "name": "Dashboarding",
      "href": "dashboard"
    },
    {
      "name": "Natural Language Processing",
      "href": "nlp"
    },
    {
      "name": "Data Analysis",
      "href": "data_analysis"
    },
    {
      "name": "Deep Learning",
      "href": "deep_learning"
    },
    {
      "name": "Python"
    },
    {
      "name": "Story Telling", 
      "href": "dashboarding"
    },
    {
      "name": "SQL"
    },
    {
      "name": "Data Engineering",
      "href": "dataengineering"
    },
    {
      "name": "Data Visualization"
    },
    {
      "name": "Jupyter"
    },
    {
      "name": "AWS"
    },
    {
      "name": "Oracle"
    },
    {
      "name": "Azure"
    },
    {
      "name": "Explainable AI",
      "href": "explainable_ai"
    },
    {
      "name": "Javascript"
    },
    {
      "name": "Ops"
    }
  ],
"links": [
{
  "source": "Data and Process Auditing",
  "target": "Python",
  "value": 5
},
{
  "source": "Data and Process Auditing",
  "target": "Story Telling",
  "value": 3
},
{
  "source": "Dashboarding",
  "target": "Story Telling",
  "value": 3
},
{
  "source": "Story Telling",
  "target": "Data Visualization",
  "value": 3
},
{
  "source": "Data and Process Auditing",
  "target": "Explainable AI",
  "value": 3
},
{
  "source": "Data and Process Auditing",
  "target": "Data Analysis",
  "value": 3
},
{
  "source": "Language Models",
  "target": "Oracle",
  "value": 3
},
{
  "source": "Language Models",
  "target": "Natural Language Processing",
  "value": 8
},
{
  "source": "Natural Language Processing",
  "target": "Python",
  "value": 8
},
{
  "source": "Language Models",
  "target": "Python",
  "value": 8
},
{
  "source": "Language Models",
  "target": "AWS",
  "value": 4
},
{
  "source": "Language Models",
  "target": "Azure",
  "value": 4
},
{
  "source": "Deep Learning",
  "target": "Oracle",
  "value": 3
},
{
  "source": "Deep Learning",
  "target": "Data Engineering",
  "value": 1
},
{
  "source": "Deep Learning",
  "target": "AWS",
  "value": 1
},
{
  "source": "Deep Learning",
  "target": "Azure",
  "value": 1
},
{
  "source": "Python",
  "target": "Data Engineering",
  "value": 2
},
{
  "source": "AWS",
  "target": "Data Engineering",
  "value": 2
},
{
  "source": "Oracle",
  "target": "Data Engineering",
  "value": 2
},
{
  "source": "Azure",
  "target": "Data Engineering",
  "value": 2
},
{
  "source": "Azure",
  "target": "Ops",
  "value": 2
},
{
  "source": "Oracle",
  "target": "Ops",
  "value": 2
},
{
  "source": "Data Analysis",
  "target": "SQL",
  "value": 2
},
{
  "source": "SQL",
  "target": "Data Visualization",
  "value": 2
},
{
  "source": "Data Analysis",
  "target": "Python",
  "value": 2
},
{
  "source": "Data Analysis",
  "target": "Data Visualization",
  "value": 2
},
{
  "source": "Jupyter",
  "target": "Python",
  "value": 2
},
{
  "source": "Story Telling",
  "target": "Jupyter",
  "value": 2
},
{
  "source": "Jupyter",
  "target": "Data Visualization",
  "value": 2
},
{
  "source": "Story Telling",
  "target": "Explainable AI",
  "value": 2
},
{
  "source": "Data Analysis",
  "target": "Explainable AI",
  "value": 2
},
{
  "source": "Story Telling",
  "target": "Data Analysis",
  "value": 2
},
{
  "source": "Dashboarding",
  "target": "Python",
  "value": 2
},
{
  "source": "Dashboarding",
  "target": "Javascript",
  "value": 2
},
{
  "source": "Javascript",
  "target": "Data Visualization",
  "value": 2
},
{
  "source": "Data Analysis",
  "target": "Javascript",
  "value": 2
},
{
  "source": "Dashboarding",
  "target": "SQL",
  "value": 2
},
{
  "source": "SQL",
  "target": "Data Engineering",
  "value": 2
},
{
  "source": "AWS",
  "target": "Ops",
  "value": 2
},
{
  "source": "Deep Learning",
  "target": "Ops",
  "value": 2
},
{
  "source": "Data and Process Auditing",
  "target": "Ops",
  "value": 2
},
{
  "source": "Dashboarding",
  "target": "Data Visualization",
  "value": 2
},
{
  "source": "Dashboarding",
  "target": "Data Analysis",
  "value": 2
}
]};
